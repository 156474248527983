

.main {
    background-image: url('../assets/mobileBackground.svg');
    min-height:110vh;
    background-size: cover;
    width:100%;
    margin:0;
    position: relative;
    overflow: hidden;
   
    

}

@media   (orientation: landscape) {
    .main {
      height: 900px;
    }
  }


@media screen and (min-width:1024px) {
    .main {
        height:125vh;
        width:100%;
        background-image: url('../assets/largeBackground.svg');
    }
}


/* @media screen and (min-width:1340px) {
  .main {
    height: 140vh;
  }
} */


.cloud1 {
  position: absolute;
  animation-name: move;
  animation-duration: 4s;
  transition: all 4s; 
  animation-iteration-count: infinite;
  animation-timing-function:cubic-bezier();

}


@keyframes move {
  

  0%{left:100px};
  10%{left:0px};
  50%{left:50%};
  80%{left:100%};

}